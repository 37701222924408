import logo from "./logomoremoremoregreen.png";
import image from "./leaveas.png";
import "./App.css";
import { Box, Center, Button, Text, VStack } from "@chakra-ui/react";
// background-color: #bfffb1;
// opacity: 0.4;
// background-image:  linear-gradient(135deg, #66eb68 25%, transparent 25%), linear-gradient(225deg, #66eb68 25%, transparent 25%), linear-gradient(45deg, #66eb68 25%, transparent 25%), linear-gradient(315deg, #66eb68 25%, #bfffb1 25%);
// background-position:  22px 0, 22px 0, 0 0, 0 0;
// background-size: 44px 44px;
// background-repeat: repeat;
function App() {
  let customColor = "#417A15";
  // 539D1B
  return (
    //     background-color: #e5e5f7;
    // opacity: 0.5;
    // background: linear-gradient(135deg, #83eb7755 25%, transparent 25%) -16px 0/ 32px 32px, linear-gradient(225deg, #83eb77 25%, transparent 25%) -16px 0/ 32px 32px, linear-gradient(315deg, #83eb7755 25%, transparent 25%) 0px 0/ 32px 32px, linear-gradient(45deg, #83eb77 25%, #e5e5f7 25%) 0px 0/ 32px 32px;
    // <Box
    //   bg="#349F2E"
    //   opacity={"0.4"}
    //   backgroundImage="linear-gradient(135deg, #66eb68 25%, transparent 25%), linear-gradient(225deg, #66eb68 25%, transparent 25%), linear-gradient(45deg, #66eb68 25%, transparent 25%), linear-gradient(315deg, #66eb68 25%, #bfffb1 25%)"
    //   backgroundPosition={"22px 0, 22px 0, 0 0, 0 0"}
    //   backgroundSize="44px 44px"
    //   backgroundRepeat={"repeat"}
    // >

    // <Box
    //   background-color="#25D064"
    //   // opacity="0.5"
    //   backgroundImage={image}
    //   h="calc(100vh)"
    // >
    // <Box
    //   background-color="#25D064"
    //   opacity="0.5"
    //   background="linear-gradient(135deg, #41953c55 25%, transparent 25%) -17px 0/ 34px 34px, linear-gradient(225deg, #41953c 25%, transparent 25%) -17px 0/ 34px 34px, linear-gradient(315deg, #41953c55 25%, transparent 25%) 0px 0/ 34px 34px, linear-gradient(45deg, #41953c 25%, #e5e5f7 25%) 0px 0/ 34px 34px"
    //   h="calc(100vh)"
    // >
    // <Box
    //   bg="#ffffff"
    //   opacity={"0.5"}
    //   backgroundImage="linear-gradient(135deg, #83eb77 25%, transparent 25%), linear-gradient(225deg, #83eb77 25%, transparent 25%), linear-gradient(45deg, #83eb77 25%, transparent 25%), linear-gradient(315deg, #83eb77 25%, #ffffff 25%)"
    //   backgroundPosition={"16px 0, 16px 0, 0 0, 0 0"}
    //   backgroundSize="32px 32px"
    //   backgroundRepeat={"repeat"}
    //   py="2%"
    // >
    // <Box
    //   bg="#ffffff"
    //   // opacity={"0.5"}
    //   // backgroundImage="linear-gradient(135deg, #83eb77 25%, transparent 25%), linear-gradient(225deg, #83eb77 25%, transparent 25%), linear-gradient(45deg, #83eb77 25%, transparent 25%), linear-gradient(315deg, #83eb77 25%, #ffffff 25%)"
    //   // backgroundPosition={"16px 0, 16px 0, 0 0, 0 0"}
    //   // backgroundSize="32px 32px"
    //   // backgroundRepeat={"repeat"}
    //   backgroundImage={image}
    //   py="2%"
    // >
    <Box
      bg="#ffffff"
      opacity={"0.5"}
      // backgroundImage="linear-gradient(135deg, #83eb77 25%, transparent 25%), linear-gradient(225deg, #83eb77 25%, transparent 25%), linear-gradient(45deg, #83eb77 25%, transparent 25%), linear-gradient(315deg, #83eb77 25%, #ffffff 25%)"
      // backgroundPosition={"16px 0, 16px 0, 0 0, 0 0"}
      // backgroundSize="32px 32px"
      // backgroundRepeat={"repeat"}
      backgroundImage="repeating-radial-gradient( circle at 0 0, transparent 0, #ffffff 16px ), repeating-linear-gradient( #83eb7755, #83eb77 )"
      py="2%"
    >
      <Box
        borderWidth={2}
        borderColor={"BlackAlpha.800"}
        py="1.5%"
        bg={"#F5F5F5"}
        borderRadius="3xl"
        my="4.5%"
        mx="16.5%"
      >
        <Center>
          <img className="logo" src={logo} />
        </Center>
        {/* <Box
              className="text"
              margin={"10%"}
              padding={"2%"}
              bg={"#d9edcc"}
              borderRadius="xl"
            > */}
        <VStack alignContent={"center"}>
          <Box textAlign={"center"}>
            <Text fontSize="3xl">
              <u>Generelt</u>
            </Text>
          </Box>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Hvordan%20man%20benytter%20Kontrol%20Panelet%20(Control%20Panel).pdf"
                >
                  <b>Hvordan man benytter Kontrol Panelet (Control Panel)</b>
                </Button>
              </li>
            </ul>
          </section>
          <Box textAlign={"center"}>
            <Text fontSize="3xl">
              <u>Patienter og pårørende</u>
            </Text>
          </Box>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Oprette%20egnede%20patient%20+%20p%C3%A5r%C3%B8rende%20tilknyttet%20bestemt%20l%C3%A6ge%20og%20udfylde%20registreringsmodulet%20-%20Patienter%20og%20p%C3%A5r%C3%B8rende.pdf"
                >
                  <b>
                    Oprette egnede patient + pårørende tilknyttet bestemt læge
                    og udfylde registreringsmodulet - Patienter og pårørende
                  </b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Sende%20sp%C3%B8rgeskemaer%20afsted%20patienter%20-%20Patienter%20og%20p%C3%A5r%C3%B8rende.pdf"
                >
                  <b>
                    Sende spørgeskemaer afsted patienter - Patienter og
                    pårørende
                  </b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Sende%20sp%C3%B8rgeskemaer%20afsted%20pårørende%20-%20Patienter%20og%20p%C3%A5r%C3%B8rende.pdf"
                >
                  <b>
                    Sende spørgeskemaer afsted pårørende - Patienter og
                    pårørende
                  </b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Udfylde%20registreringsskema%20for%20interventions%20samtaler%20-%20Patienter%20og%20p%C3%A5r%C3%B8rende.pdf"
                >
                  <b>
                    Udfylde registreringsskema for interventions samtaler -
                    Patienter og pårørende
                  </b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Registrering%20af%20d%C3%B8dsfald%20-%20Patienter%20og%20p%C3%A5r%C3%B8rende.pdf"
                >
                  <b>Registrering af dødsfald - Patienter og pårørende</b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Dropout%20af%20l%C3%A6gen%20eller%20l%C3%A6geskift%20-%20Patienter%20og%20p%C3%A5r%C3%B8rende.pdf"
                >
                  <b>
                    Dropout af lægen eller lægeskift - Patienter og pårørende
                  </b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Dropout%20-%20Patienter%20og%20p%C3%A5r%C3%B8rende.pdf"
                >
                  <b>Dropout - Patienter og pårørende</b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Upload%20af%20fil%20-%20Patienter%20og%20p%C3%A5r%C3%B8rende.pdf"
                >
                  <b>Upload af fil - Patienter og pårørende</b>
                </Button>
              </li>
            </ul>
          </section>
          {/* <Box textAlign={"center"}>
            <Text fontSize="3xl">
              <u>Læger og sygeplejersker</u>
            </Text>
          </Box>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Registrere%20at%20de%20har%20haft%205%20samtaler%20metode%201%20-%20L%C3%A6ge%20og%20sygeplejerske.pdf"
                >
                  <b>
                    Registrere at de har haft 5 samtaler metode 1 - Læge og
                    sygeplejerske
                  </b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Registrere%20at%20de%20har%20haft%205%20samtaler%20metode%202%20(manuelt)%20-%20L%C3%A6ge%20og%20sygeplejerske.pdf"
                >
                  <b>
                    Registrere at de har haft 5 samtaler metode 2 (manuelt) -
                    Læge og sygeplejerske
                  </b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Dropout%20-%20L%C3%A6ge%20og%20sygeplejerske.pdf"
                >
                  <b>Dropout - Læge og sygeplejerske</b>
                </Button>
              </li>
            </ul>
          </section>
          <section className="effect-9">
            <ul>
              <li className="center-border">
                <Button
                  borderRadius={0}
                  _hover={{
                    textColor: "#ffffff",
                    borderColor: { customColor },
                  }}
                  variant="outline"
                  colorScheme="whatsapp"
                  borderColor={customColor}
                  textColor={customColor}
                  as="a"
                  href="https://slb.viceron.com/Dropout%20-%20L%C3%A6ge%20og%20sygeplejerske.pdf"
                >
                  <b>Dropout - Læge og sygeplejerske</b>
                </Button>
              </li>
            </ul>
          </section> */}
        </VStack>

        {/* </Box> */}
      </Box>
    </Box>
  );
}

export default App;
